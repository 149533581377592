import Cookies from "js-cookie";

export const AUTH: Auth = new Proxy(
  {
    EC_BENEFITS_PC_TOKEN: "",
  },
  {
    get(target, key) {
      return Reflect.get(target, key);
    },
    set(target, key, value) {
      Cookies.set(key as string, value, { expires: 7, path: "/" });
      return Reflect.set(target, key, value);
    },
  },
);

export function initAuth() {
  Object.keys(AUTH).forEach((key) => {
    const value = Cookies.get(key);
    if (value !== undefined) {
      // @ts-ignore
      AUTH[key] = value;
    }
  });
}

export function clearAuth() {
  Object.keys(AUTH).forEach((key) => {
    // @ts-ignore
    AUTH[key] = "";
    Cookies.remove(key);
  });
}

export function isAuth() {
  return !!AUTH.EC_BENEFITS_PC_TOKEN;
}

export interface Auth {
  EC_BENEFITS_PC_TOKEN?: string;
}
