import { FETCH_CODE, Result } from "@/configs";
import { message, Modal, ModalFuncProps } from "ant-design-vue";

// 全局提示组件
export function showMsg(options: Result): Promise<boolean> {
  const { flag, msg, title } = options;

  return new Promise((resolve) => {
    if (!msg && !title) {
      resolve(false);
      return;
    }
    if (flag === FETCH_CODE.SC.KEY) {
      message.success(msg, 2).then(() => {
        resolve(true);
      });
      return;
    }
    if (flag === FETCH_CODE.WN.KEY) {
      message.warning(msg, 2).then(() => {
        resolve(true);
      });
      return;
    }
    if (flag === FETCH_CODE.ERM.KEY) {
      message.error(msg, 2).then(() => {
        resolve(true);
      });
      return;
    }
    let error: ModalFuncProps = {
      title: msg,
    };
    if (title) {
      error = {
        title,
        content: msg,
      };
    }
    Modal.error({
      ...error,
      okText: "确定",
      onOk: () => {
        resolve(true);
      },
    });
  });
}
