import wxPay from "../assets/img/mall/Wepay-Ico.png";
import aliPay from "../assets/img/mall/Alipay-Ico.png";

// 支付方式字典
export const PAY_TYPE = [
  {
    id: 221,
    dictType: "pay_type",
    dictCode: "only_points",
    dictName: "福币支付",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: 222,
    dictType: "pay_type",
    dictCode: "only_price",
    dictName: "现金支付",
    dictIcon: "",
    sort: 2,
    remark: "",
    state: true,
  },
  {
    id: 223,
    dictType: "pay_type",
    dictCode: "points_price",
    dictName: "福币+现金",
    dictIcon: "",
    sort: 3,
    remark: "",
    state: true,
  },
  {
    id: 224,
    dictType: "pay_type",
    dictCode: "growth_points",
    dictName: "成长积分支付",
    dictIcon: "",
    sort: 4,
    remark: "",
    state: true,
  },
];

// 现金支付字典
export const PAYMENT_TYPE = {
  WX_JS: {
    KEY: Symbol("wx_js"),
    CD: 1,
    NAME: "微信JSAPI",
  },
  WX_NATIVE: {
    KEY: Symbol("wx_native"),
    CD: 2,
    NAME: "微信NATIVE",
  },
  WX_APP: {
    KEY: Symbol("wx_app"),
    CD: 4,
    NAME: "微信APP",
  },
  WX_H5: {
    KEY: Symbol("wx_h5"),
    CD: 3,
    NAME: "微信H5",
  },
  ALI_PAY: {
    KEY: Symbol("ali_pay"),
    CD: 6,
    NAME: "支付宝",
  },
  ALI_PAY_SCALE: {
    KEY: Symbol("ali_pay_scale"),
    CD: 5,
    NAME: "支付宝扫码",
  },
};

// 订单支付选项
export const PAY_WAY = {
  COIN: {
    KEY: Symbol("points"),
    CD: "1",
    DIC: PAY_TYPE[0].dictCode,
    NAME: "福币支付",
    ENABLE: true,
  },
  WECHAT: {
    KEY: Symbol("wechat"),
    CD: "2",
    DIC: PAY_TYPE[1].dictCode,
    CASH: PAYMENT_TYPE.WX_NATIVE.CD,
    NAME: "微信支付",
    ICON: wxPay,
    ENABLE: true,
  },
  ALI: {
    KEY: Symbol("ali"),
    CD: "3",
    DIC: PAY_TYPE[1].dictCode,
    CASH: PAYMENT_TYPE.ALI_PAY_SCALE.CD,
    NAME: "支付宝",
    ICON: aliPay,
    ENABLE: true,
  },
};

// 支付状态 0=待付款 1=支付成功 2=已关闭 3=支付失败
export const TRADE_STATE = [
  {
    id: "trade_state_0",
    dictType: "trade_state",
    dictCode: 0,
    dictName: "待付款",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: "trade_state_1",
    dictType: "trade_state",
    dictCode: 1,
    dictName: "支付成功",
    dictIcon: "",
    sort: 2,
    remark: "",
    state: true,
  },
  {
    id: "trade_state_2",
    dictType: "trade_state",
    dictCode: 2,
    dictName: "已关闭",
    dictIcon: "",
    sort: 3,
    remark: "",
    state: true,
  },
  {
    id: "trade_state_3",
    dictType: "trade_state",
    dictCode: 3,
    dictName: "支付失败",
    dictIcon: "",
    sort: 4,
    remark: "",
    state: true,
  },
];

// 微信支付状态
export const WECHAT_TRADE_STATE = [
  {
    id: "wechat_trade_state_0",
    dictType: "wechat_trade_state",
    dictCode: "SUCCESS",
    dictName: "订单支付成功",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: "wechat_trade_state_1",
    dictType: "wechat_trade_state",
    dictCode: "REFUND",
    dictName: "转入退款",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: "wechat_trade_state_2",
    dictType: "wechat_trade_state",
    dictCode: "NOTPAY",
    dictName: "未支付",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: "wechat_trade_state_3",
    dictType: "wechat_trade_state",
    dictCode: "CLOSED",
    dictName: "已关闭",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: "wechat_trade_state_4",
    dictType: "wechat_trade_state",
    dictCode: "REVOKED",
    dictName: "已撤销",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: "wechat_trade_state_5",
    dictType: "wechat_trade_state",
    dictCode: "USERPAYING",
    dictName: "用户支付中",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
  {
    id: "wechat_trade_state_6",
    dictType: "wechat_trade_state",
    dictCode: "PAYERROR",
    dictName: "支付失败",
    dictIcon: "",
    sort: 1,
    remark: "",
    state: true,
  },
];

// 金额换算比率 后端存 *100 前端显示*0.01
export const PRICE_RATE_BACK = 100;
export const PRICE_RATE_FRONT = 0.01;
export const PRICE_DECIMALS = 2;
