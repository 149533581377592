import { Directive } from "vue";

// 为当前元素赋值动态背景图片
export const bgImg: Directive = {
  mounted(el, binding) {
    el.style.backgroundImage = `url("${binding.value}")`;
    el.style.backgroundPosition = "center";
    el.style.backgroundRepeat = "no-repeat";
    el.style.backgroundSize = "contain";
  },
  updated(el, binding) {
    el.style.backgroundImage = `url("${binding.value}")`;
  },
};
