import axios, { AxiosRequestConfig, ResponseType } from "axios";
import {
  AUTH,
  clearAuth,
  FETCH_CODE,
  FETCH_CONTENT_TYPE,
  FETCH_TIMEOUT,
  Method,
  RES_CODE,
  Result,
} from "@/configs";
import router from "@/routers";

class Api {
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = "/api";
  }

  // 发起 GET 请求
  public get<Data>(
    url: string,
    data?: Data,
    options?: Options,
  ): Promise<Result> {
    const params = this.formatRequestParams<Data>(url, "GET", data, options);
    return this.request<Data>(params);
  }

  // 发起 POST 请求
  public post<Data>(
    url: string,
    data?: Data,
    options?: Options,
  ): Promise<Result> {
    const params = this.formatRequestParams<Data>(url, "POST", data, options);
    return this.request<Data>(params);
  }

  // 下载二进制流
  public download<Data>(
    url: string,
    data?: Data,
    options?: Options,
  ): Promise<Result> {
    const params = this.formatRequestParams<Data>(
      url,
      options?.method || "POST",
      data,
      {
        ...options,
        responseType: "arraybuffer",
      },
    );
    const result: Result = {
      flag: FETCH_CODE.ER.KEY,
      msg: FETCH_CODE.ER.NAME,
      data: null,
    };
    return new Promise((resolve) => {
      axios(params)
        .then((response) => {
          if (!response.data) {
            resolve(result);
            return;
          }
          result.flag = FETCH_CODE.SC.KEY;
          result.data = response.data;
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.cssText = "display: none;";
          link.href = url;
          let fileName = response.data.fileName;
          if (!fileName) {
            fileName = decodeURIComponent(
              response.headers["content-disposition"].split("=")[1],
            );
          }
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          resolve(result);
        })
        .catch((error) => {
          result.msg = `请求失败: ${error.toString()}`;
          resolve(result);
        });
    });
  }

  // 序列化请求配置和参数
  private formatRequestParams<Data>(
    url: string,
    method: Method,
    data?: Data,
    options?: Options,
  ): AxiosRequestConfig<Params<Data>> {
    const params: AxiosRequestConfig<Params<Data>> = {
      data: data as Params<Data>,
      method,
      timeout: FETCH_TIMEOUT,
      withCredentials: true,
      responseType: "json",
      responseEncoding: "utf8",
      url: `${this.baseUrl}/${url.replace(/^\//, "")}`,
      ...options,
      headers: {
        Authorization: AUTH.EC_BENEFITS_PC_TOKEN,
        "Content-Type": FETCH_CONTENT_TYPE,
        // @ts-ignore
        ...options?.headers,
      },
    };
    if (method === "GET") {
      params.params = data;
    }
    return params;
  }

  // 发起请求
  private request<Data>(
    params: AxiosRequestConfig<Params<Data>>,
  ): Promise<Result> {
    const result: Result = {
      flag: FETCH_CODE.ER.KEY,
      msg: FETCH_CODE.ER.NAME,
      data: null,
    };
    return new Promise((resolve) => {
      axios(params)
        .then((response) => {
          const { data } = response;
          if (data.code === RES_CODE.SUCCESS.KEY) {
            result.flag = FETCH_CODE.SC.KEY;
            result.msg = FETCH_CODE.SC.NAME;
            result.data = data.data;
            if (data.tid) {
              result.tid = data.tid;
            }
            resolve(result);
            return;
          }
          result.flag = FETCH_CODE.WN.KEY;
          result.msg = data.msg || data.data?.msg || "请求失败！";
          result.data = data.data;
          resolve(result);
        })
        .catch((error) => {
          result.msg = `请求失败: ${error.toString()}`;
          try {
            const { data } = error.response;
            result.msg = data.msg;
            result.data = data;
          } catch (err) {
            console.log(err);
          }
          resolve(result);
        });
    });
  }
}

// axios 全局配置
export function initAxios() {
  axios.defaults.baseURL = "/";
  axios.defaults.headers.common["Content-Type"] = FETCH_CONTENT_TYPE;
  axios.defaults.timeout = FETCH_TIMEOUT;

  axios.interceptors.request.use(
    (conf) => {
      if (conf.method?.toUpperCase() === "GET") {
        conf.params ? conf.params : (conf.params = {});
        Object.assign(conf.params, { ts: +new Date() });
      }
      return conf;
    },
    (err) => {
      return Promise.reject(err);
    },
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      try {
        const { data } = err.response;
        // 未登录清空cookie并刷新页面
        if (
          data.code === RES_CODE.UN_AUTHORIZED.KEY &&
          !/^\/account/.test(window.location.pathname)
        ) {
          clearAuth();
          router.replace("/account");
        }
      } catch (e) {
        console.error(e);
      }
      return Promise.reject(err);
    },
  );

  return axios;
}

export const api = new Api();

interface Options {
  responseType?: ResponseType;
  method?: Method;
}

interface Params<Data> {
  data?: Data;
  params?: any;
  method?: Method;
  timeout?: number;
  withCredentials?: boolean;
  responseType?: ResponseType;
  responseEncoding?: string;
  url?: string;
  headers?: headers;
}

interface headers {
  Authorization?: string;
  "Content-Type"?: string;
}
