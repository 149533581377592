import { RouteRecordRaw } from "vue-router";

// @ts-ignore
const Account = () => import("@/pages/account/Index.vue");
// @ts-ignore
const SignIn = () => import("@/pages/account/SignIn.vue");
// @ts-ignore
const SignUp = () => import("@/pages/account/sign-up/Index.vue");
// @ts-ignore
const SignUpHome = () => import("@/pages/account/sign-up/Add.vue");
// @ts-ignore
const FindPwd = () => import("@/pages/account/sign-up/Pwd.vue");

export const routes: RouteRecordRaw[] = [
  {
    path: "/account",
    component: Account,
    children: [
      {
        path: "",
        alias: "/account/sign-in",
        name: "SignIn",
        component: SignIn,
        meta: {
          skipAuth: true,
          title: "登录",
        },
      },
      {
        path: "sign-up",
        component: SignUp,
        meta: {
          skipAuth: true,
        },
        children: [
          {
            path: "",
            name: "SignUp",
            component: SignUpHome,
            meta: {
              skipAuth: true,
              title: "注册",
            },
          },
          {
            path: "find-pwd",
            alias: "/account/find-pwd",
            name: "FindPwd",
            component: FindPwd,
            meta: {
              skipAuth: true,
              title: "找回密码",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
