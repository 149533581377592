import { RouteRecordRaw } from "vue-router";

// @ts-ignore
const Content = () => import("@/pages/Index.vue");
// @ts-ignore
const Home = () => import("@/pages/home/Index.vue");
// @ts-ignore
const Coupon = () => import("@/pages/coupon/Index.vue");
// @ts-ignore
const HomeIndex = () => import("@/pages/home/Home.vue");
// @ts-ignore
const Mall = () => import("@/pages/home/mall/Index.vue");
// @ts-ignore
const MallDetail = () => import("@/pages/home/mall/Detail.vue");
// @ts-ignore
const Cart = () => import("@/pages/home/mall/Cart.vue");
// @ts-ignore
const Pay = () => import("@/pages/home/mall/Order.vue");
// @ts-ignore
const PayResult = () => import("@/pages/home/mall/Result.vue");
// @ts-ignore
const AliPayResult = () => import("@/pages/home/mall/AliPayResult.vue");
// @ts-ignore
const User = () => import("@/pages/home/user/Index.vue");
// @ts-ignore
const OrderList = () => import("@/pages/home/order/Index.vue");
// @ts-ignore
const OrderDetail = () => import("@/pages/home/order/Detail.vue");
// @ts-ignore
const OrderSold = () => import("@/pages/home/order/sold/Index.vue");
// @ts-ignore
const OrderSoldApply = () => import("@/pages/home/order/sold/Apply.vue");
// @ts-ignore
const OrderSoldDetail = () => import("@/pages/home/order/sold/Detail.vue");
// @ts-ignore
const UserInfo = () => import("@/pages/home/user/Info.vue");
// @ts-ignore
const UserSafe = () => import("@/pages/home/user/Safe.vue");
// @ts-ignore
const Address = () => import("@/pages/home/address/Index.vue");
// @ts-ignore
const Helper = () => import("@/pages/home/help/Index.vue");

export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: Content,
    children: [
      {
        path: "",
        component: Home,
        children: [
          {
            path: "",
            alias: "/home",
            name: "Home",
            component: HomeIndex,
            meta: {
              title: "首页",
            },
          },
          {
            path: "mall",
            name: "Mall",
            component: Mall,
            meta: {
              title: "商城列表",
            },
          },
          {
            path: "mall/:productNo",
            name: "MallDetail",
            component: MallDetail,
            meta: {
              title: "商品详情",
            },
          },
          {
            path: "cart",
            name: "Cart",
            component: Cart,
            meta: {
              title: "购物车",
            },
          },
          {
            path: "pay",
            name: "Pay",
            component: Pay,
            meta: {
              title: "订单确认",
            },
          },
          {
            path: "pay/:flag",
            name: "PayResult",
            component: PayResult,
            meta: {
              title: "订单支付结果",
            },
          },
          {
            path: "pay/alipay/result",
            name: "PayResult",
            component: AliPayResult,
            meta: {
              title: "支付宝支付结果",
            },
          },
          {
            path: "user",
            component: User,
            children: [
              {
                path: "order",
                name: "Order",
                component: OrderList,
                meta: {
                  title: "我的订单",
                },
              },
              {
                path: "order/:orderId",
                name: "OrderDetail",
                component: OrderDetail,
                meta: {
                  title: "订单详情",
                },
              },
              {
                path: "sold",
                name: "OrderSold",
                component: OrderSold,
                meta: {
                  title: "售后订单",
                },
              },
              {
                path: "sold/apply/:id",
                name: "OrderSoldApply",
                component: OrderSoldApply,
                meta: {
                  title: "申请售后",
                },
              },
              {
                path: "sold/:orderId/:type?",
                name: "OrderSoldDetail",
                component: OrderSoldDetail,
                meta: {
                  title: "售后订单详情",
                },
              },
              {
                path: "info",
                name: "UserInfo",
                component: UserInfo,
                meta: {
                  title: "帐号信息",
                },
              },
              {
                path: "safe",
                name: "UserSafe",
                component: UserSafe,
                meta: {
                  title: "安全设置",
                },
              },
              {
                path: "address",
                name: "Address",
                component: Address,
                meta: {
                  title: "地址管理",
                },
              },
              {
                path: "helper",
                name: "Helper",
                component: Helper,
                meta: {
                  title: "常见问题",
                },
              },
            ],
          },
        ],
      },
      {
        path: "coupon",
        name: "Coupon",
        component: Coupon,
        meta: {
          title: "节日卡券",
        },
      },
    ],
  },
];

export default routes;
