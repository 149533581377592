import { api, showMsg } from "@/utils";
import { FETCH_CODE } from "@/configs";

// 请求一个字典
export function getDic(params: GetDicParams) {
  return api.get("/sys/dict/get", {
    ...params,
  });
}

export interface GetDicParams {
  dictType?: string;
}

// 获取系统配置
export async function getSys(params: GetSysParams): Promise<Sys> {
  const res = await api.post("/sys/data/page", {
    ...params,
    pageNum: 1,
    pageSize: 1,
    state: true,
  });
  if (res.flag !== FETCH_CODE.SC.KEY) {
    showMsg(res);
    return {};
  }
  return res.data.records[0] || {};
}

interface GetSysParams {
  // 查询的系统配置编码
  search?: string;
}

interface Sys {
  code?: string;
  id?: number;
  name?: string;
  value?: string;
  remark?: string;
  isEncryption?: boolean;
}
