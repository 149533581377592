// 前端请求状态分类
export const FETCH_CODE_SC: Code<FetchCodeKey> = {
  KEY: 0,
  NAME: "请求成功",
};
export const FETCH_CODE_WN: Code<FetchCodeKey> = {
  KEY: 1,
  NAME: "请求有警告",
};
export const FETCH_CODE_ER: Code<FetchCodeKey> = {
  KEY: 2,
  NAME: "请求错误",
};
export const FETCH_CODE_ERM: Code<FetchCodeKey> = {
  KEY: 3,
  NAME: "请求错误",
};
export const FETCH_CODE = {
  SC: FETCH_CODE_SC,
  WN: FETCH_CODE_WN,
  ER: FETCH_CODE_ER,
  ERM: FETCH_CODE_ERM,
};

// 后端接口 code 约定
export const RES_CODE_SC: Code<ResCodeKey> = {
  KEY: 200,
  NAME: "请求成功",
};
export const RES_CODE_ER: Code<ResCodeKey> = {
  KEY: 500,
  NAME: "请求失败",
};
export const RES_CODE_IU: Code<ResCodeKey> = {
  KEY: 1001,
  NAME: "账号或密码错误",
};
export const RES_CODE_UA: Code<ResCodeKey> = {
  KEY: 401,
  NAME: "Unauthorized",
};
export const RES_CODE_BC: Code<ResCodeKey> = {
  KEY: 1005,
  NAME: "未绑定企业",
};
export const RES_CODE = {
  // 请求成功
  SUCCESS: RES_CODE_SC,
  // 请求失败
  ERROR: RES_CODE_ER,
  // 账号或者密码错误
  INVALID_USER: RES_CODE_IU,
  // 未登录/认证
  UN_AUTHORIZED: RES_CODE_UA,
  // 未绑定企业
  UN_BIND_COMPANY: RES_CODE_BC,
};

// 请求超时控制
export const FETCH_TIMEOUT = 100000;

// 请求头类型
export const FETCH_CONTENT_TYPE = "application/json;charset=UTF-8";

// 请求成功
export type FetchCodeSuccess = 0;
// 请求有警告
export type FetchCodeWarning = 1;
// 请求错误
export type FetchCodeError = 2;
// 请求错误
export type FetchCodeErrorMsg = 3;

export type FetchCodeKey =
  | FetchCodeSuccess
  | FetchCodeWarning
  | FetchCodeError
  | FetchCodeErrorMsg;

// 请求成功
export type ResCodeSuccess = 200;
// 请求失败
export type ResCodeError = 500;
// 账号或密码错误
export type ResCodeInvalidUser = 1001;
// 未登录/认证
export type ResCodeUnAuthorized = 401;
// 未绑定企业
export type ResCodeBindCompany = 1005;

export type ResCodeKey =
  | ResCodeSuccess
  | ResCodeError
  | ResCodeInvalidUser
  | ResCodeUnAuthorized
  | ResCodeBindCompany;

export interface Code<T> {
  KEY: T;
  NAME: string;
}

export type Method = "POST" | "GET";

export interface Result {
  flag: FetchCodeKey;
  msg?: string;
  data?: any;
  tid?: string;
  title?: string;
}
