import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { api, moneyFormatter } from "@/utils";
import { FETCH_CODE, PAY_TYPE, PAY_WAY, PRICE_RATE_FRONT } from "@/configs";
import BigNumber from "bignumber.js";

export const useUserStore = defineStore("userStore", () => {
  // ref()s become state properties
  // computed()s become getters
  // function()s become actions

  // 登录用户信息
  const info = ref({
    id: null,
    delFlag: false,
    createBy: null,
    createTime: null,
    updateBy: null,
    updateTime: null,
    userId: null,
    name: null,
    gender: false,
    mobilePhoneCode: null,
    mobilePhoneNumber: null,
    mobilePhoneNumberVerified: false,
    telephoneNumber: null,
    emailAddress: null,
    password: null,
    passwordSalt: null,
    isDisabled: false,
    type: 3,
    headImg: null,
    currentCompany: {
      companyId: null,
      parentCompanyId: null,
      name: null,
      nameShort: null,
      nameOther: null,
      nameOtherShort: null,
      taxCode: null,
      country: null,
      province: null,
      city: null,
      district: null,
      address: null,
      typeId: 0,
      industryId: null,
      scaleId: 0,
      sourceId: 0,
      categoryId: 0,
      intro: null,
      businessUserId: null,
      serviceUserId: null,
    },
    companies: null,
    roleIds: [],
  });
  // 福币余额
  const balance = ref(0);
  // 是否设置了支付密码
  const payPwdSetted = ref(false);
  // 平台配置信息
  const config = ref({
    name: null,
    logo: null,
    moneyName: null,
    tool: false,
  });
  // 手续费
  const serviceCharge = ref(0);

  const balanceText = computed(() => {
    return moneyFormatter(balance.value);
  });

  // 获取用户信息
  const getInfo = async () => {
    const res = await api.get("/user/info");
    if (res.flag !== FETCH_CODE.SC.KEY) {
      return;
    }
    info.value = res.data;
    // 平台配置信息设置
    config.value = {
      ...config.value,
      ...res.data.platformConf,
      moneyName: res.data.platformConf.moneyName || "福币",
    };
    // 更新本地字典
    PAY_TYPE[0].dictName = `${config.value.moneyName}支付`;
    PAY_WAY.COIN.NAME = `${config.value.moneyName}支付`;
    // 获取手续费
    let serviceChargeRes = 0.03;
    const dynamicServiceCharge = res.data?.currentCompany?.serviceCharge;
    if (typeof dynamicServiceCharge === "number") {
      serviceChargeRes = dynamicServiceCharge;
    }
    serviceCharge.value = new BigNumber(serviceChargeRes).times(100).toNumber();
    // 更新HTML的title
    document.title = config.value.name || "";
    // 获取余额
    getPoints();
  };

  // 获取福币余额
  const getPoints = async () => {
    const res = await api.get("/user/points");
    if (res.flag !== FETCH_CODE.SC.KEY) {
      balance.value = 0;
      return;
    }
    let _balance = new BigNumber(res.data);
    _balance = _balance.times(PRICE_RATE_FRONT);
    balance.value = _balance.toNumber();
  };

  // 福币支付密码是否已经设置
  const getPayPwdSetted = async () => {
    const res = await api.get("/user/checkPayPwd");
    if (res.flag !== FETCH_CODE.SC.KEY) {
      payPwdSetted.value = false;
      return;
    }
    payPwdSetted.value = !!res.data;
  };

  return {
    info,
    balance,
    balanceText,
    payPwdSetted,
    config,
    serviceCharge,
    getPayPwdSetted,
    getInfo,
    getPoints,
  };
});
