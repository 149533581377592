import { createApp } from "vue";
// @ts-ignore
import App from "./App.vue";
import "./styles/_reboot.less";
import "./style.less";
import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/zh-cn";
import router from "./routers";
import { initAuth } from "@/configs";
import { initAxios } from "@/utils";
import { useUserStore } from "@/stores/user.ts";
import { createPinia } from "pinia";
import { makeDirectives } from "@/directives";

dayjs.extend(isLeapYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.locale("zh-cn");

initAuth();
initAxios();

const pinia = createPinia();

const app = createApp(App);

makeDirectives(app);

app.use(pinia);
app.use(router);

const userStore = useUserStore();

userStore.getInfo();

app.mount("#app");
