// 获取数据类型
import BigNumber from "bignumber.js";
import {
  DateFormat,
  Dict,
  FETCH_CODE,
  PICTURE_SPLITTER,
  PRICE_DECIMALS,
  PRICE_RATE_BACK,
  PRICE_RATE_FRONT,
} from "@/configs";
import { getDic } from "@/api.ts";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";

export function getType(value: any): string {
  return Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
}

// 格式化数字
export function numberFormatter(
  number: number | BigNumber,
  precision = 0,
  isRound = true,
): string {
  if (getType(precision) !== "number") precision = 0;
  const num = new BigNumber(number);
  if (isRound) {
    return num.toFixed(precision);
  }
  return num.toFixed(precision, 1);
}

// 福币、金额转字符串
export function moneyFormatter(number: number) {
  return new BigNumber(number).toFixed(PRICE_DECIMALS);
}

// 积分、福币、金额展示格式化方法
export function pointsShowFormatter(number: number, isToNumber = false) {
  if (number === null || number === undefined) {
    if (isToNumber) {
      return 0;
    }
    return "-";
  }
  if (isToNumber) {
    return new BigNumber(number).times(PRICE_RATE_FRONT).toNumber();
  }
  return new BigNumber(number).times(PRICE_RATE_FRONT).toFixed(PRICE_DECIMALS);
}

// 积分、福币、金额存储格式化方法
export function pointsSaveFormatter(number: number) {
  return new BigNumber(number).times(PRICE_RATE_BACK).toNumber();
}

// 积分、福币、金额展示格式化方法 列表序列化 转换后的值为：[原键名]Show
export function listPointsShowFormatter(list: Obj[], keys: string[] | string) {
  if (getType(keys) === "string") keys = [keys as string];
  return list.map((it) => {
    for (const key of keys) {
      if (it[key] !== void 0) {
        it[`${key}Text`] = pointsShowFormatter(it[key] || 0);
      }
    }
    return { ...it };
  });
}

// 商城物品图片获取第一个
export function productItemImgFormatter(pictureUrl: string) {
  return pictureUrl ? pictureUrl.split(PICTURE_SPLITTER)[0] : "";
}

// query请求格式化
export function queryUrlFormatter(url: string, data: Obj) {
  if (!data) return url;
  // check url has ?
  const parseUrl = () => {
    if (/\?/.test(url)) {
      // is end of ?
      if (/\?$/.test(url)) {
        return url;
      }
      return url + "&";
    }
    return url + "?";
  };

  url = parseUrl();

  if (getType(data) === "String") return url + data;
  let str = "";
  const values = Object.values(data);
  const urlParams = new URLSearchParams(url.split("?")[1]);
  Object.keys(data).forEach((key, idx) => {
    let val = values[idx];
    if (val == null) val = "";
    val = val + "";
    // 参数已经存在 尝试去修改 而不是追加
    if (urlParams.has(key)) {
      urlParams.set(key, val);
      return;
    }
    str += `${key}=${val}&`;
  });
  url = url.split("?")[0] + "?" + urlParams.toString();
  if (urlParams.size > 0) {
    url += "&";
  }
  const resultUrl = url + str;
  return resultUrl.replace(/&$/, "");
}

// 文件大小数值格式化
export function fileSizeFormatter(value: number, precision = 0) {
  const num = BigNumber(value);
  // 文件小于1KB
  if (num.lt(1024)) {
    return num.toString() + "B";
  }
  // 文件小于1MB
  if (num.lt(1048576)) {
    const kb = num.div(1024);
    return numberFormatter(kb, precision) + "KB";
  }
  // 文件小于1GB
  if (num.lt(1073741824)) {
    const mb = num.div(1048576);
    return numberFormatter(mb, precision) + "MB";
  }
  // 其他按照GB处理
  const gb = num.div(1073741824);
  return numberFormatter(gb, precision) + "GB";
}

// 列表字典反转name
export function listDicFormatter(list: Obj[], dicList: Dic[]): Promise<Obj[]> {
  const ls = list.slice();
  const dicApis: Promise<{ data: any; key: string } | false>[] = [];
  const dic0bj: Obj = {};
  dicList.forEach((it) => {
    // 本地字典（未配置到系统中 硬编码）
    if (it.local) {
      dic0bj[it.key] = it.code;
      return;
    }
    const fn = async () => {
      const res = await getDic({ dictType: it.code });
      if (res.flag === FETCH_CODE.SC.KEY) {
        return { key: it.key, data: res.data };
      }
      return false;
    };
    dicApis.push(fn());
  });
  return new Promise((resolve) => {
    Promise.all(dicApis).then((res) => {
      res.forEach((it) => {
        if (!it) return;
        dic0bj[it.key] = it.data;
      });

      ls.forEach((it) => {
        for (const key in dic0bj) {
          if (it[key] !== void 0) {
            const curr = dic0bj[key].find(
              (_it: Dict) => _it.dictCode === it[key],
            );
            if (curr) {
              it[`${key}Text`] = curr.dictName;
            }
          }
        }
      });

      resolve(ls);
    });
  });
}

// 列表转为树形数组
export function list2Tree<T>(list: T[], options?: List2TreeOptions) {
  const {
    keyName = "key",
    parentKeyName = "parentKey",
    childrenName = "children",
  } = options || {};
  const map = list.reduce((acc, o) => {
    // @ts-ignore
    acc[o[keyName]] = o;
    return acc;
  }, {} as Obj);
  return list.reduce((acc: T[], o) => {
    // @ts-ignore
    if (map[o[parentKeyName]]) {
      // @ts-ignore
      map[o[parentKeyName]][childrenName] =
        // @ts-ignore
        map[o[parentKeyName]][childrenName] || [];
      // @ts-ignore
      map[o[parentKeyName]][childrenName].push(o);
    } else {
      acc.push(o);
    }

    return acc;
  }, []);
}

// 树形数组转换为列表
export function tree2List<T>(tree: T[], options?: List2TreeOptions) {
  const { childrenName = "children" } = options || {};
  return tree.reduce((acc: T[], o) => {
    if (o) {
      acc.push(o);
    }
    // @ts-ignore
    if (o[childrenName]) {
      // @ts-ignore
      acc = acc.concat(tree2List(o[childrenName], options));
    }
    return acc;
  }, []);
}

// 获取form表单第一个错误信息
export function getFormModelFirstError(error: Obj): string {
  const { errorFields } = error;
  let msg;
  try {
    msg = errorFields[0]?.errors[0];
  } catch (err) {
    console.log(err);
  }
  return msg;
}

// 时间区间格式化
export function rangeDateFormatter(range: Dayjs[], str = DateFormat) {
  const result = { start: "", end: "" };
  if (!range) return result;
  if (range[0]) {
    result.start = dayjs(range[0]).startOf("date").format(str);
  }
  if (range[1]) {
    result.end = dayjs(range[1]).endOf("date").format(str);
  }
  return result;
}

// 判断是否是json串
export function isJsonStr(str: string) {
  try {
    if (typeof JSON.parse(str) == "object") {
      return true;
    }
  } catch (e) {
    if (e === "fuck") {
      console.log(e);
    }
  }
  return false;
}

export function getFakeId() {
  const str = () => Math.random().toString(16).slice(2, 10);
  return `_local${new Date().getTime()}-${str()}-${str()}`;
}

export interface Obj {
  [k: string]: any;
}

export interface Dic {
  key: string;
  code: string;
  local?: boolean;
}

export interface List2TreeOptions {
  keyName?: string;
  parentKeyName?: string;
  childrenName?: string;
}
