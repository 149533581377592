import "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import account from "./account.ts";
import content from "./content.ts";
// @ts-ignore
import NotFound from "@/pages/NotFound.vue";
import { FETCH_CODE, isAuth } from "@/configs";
import { showMsg } from "@/utils";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...account,
    ...content,
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound,
    },
  ],
});

// @ts-ignore
router.beforeEach((to, form, next) => {
  let isExclude = false;
  const isLoggedIn = isAuth();

  if (to.meta.skipAuth) {
    isExclude = true;
  }

  if (!isExclude && !isLoggedIn) {
    showMsg({
      flag: FETCH_CODE.ERM.KEY,
      msg: "未登录",
    });
    next({ name: "SignIn", replace: true });
    return false;
  }
  next();
});

export default router;

declare module "vue-router" {
  interface RouteMeta {
    // 是否跳过登录校验
    skipAuth?: boolean;
    title?: string;
  }
}
